import React, { useState } from 'react';
import Layout from '../../../components/Layout';
import axios from 'axios';

const FAQ = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const onSend = async () => {
    try {
      const res = await axios.post(`https://us-central1-iconic-mariner-146115.cloudfunctions.net/linearsolutions-contact`, {
        name,
        email,
        subject,
        message,
      });
      setSent(true);
    } catch (err) {
      console.log(err);
      setSent(true);
      setError(true);
    }
  };

  return (
    <Layout fullMenu logoUrl="/NM/engineering">
      <article id="main">
        <header>
          <h2>Frequently Asked Questions</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <iframe
              src="https://docs.google.com/gview?url=https://storage.googleapis.com/advancece/Cosmetology/Ohio/OH%20Cosmmo%20FAQs.docx&embedded=true"
              width="100%"
              height="4800px"
            ></iframe>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default FAQ;
